footer {
  width: 100%;
  height: auto;
  background-color: whitesmoke;
  padding: 50px 100px;
}

@media screen and (max-width: 650px) {
  footer {
    padding: 30px;
  }
}/*# sourceMappingURL=templateFooter.css.map */