.disclaimer {
  margin-bottom: 100px;
}

form {

  section {

    display: grid;
    grid-template-areas: 
      "applicant ."
      "email tel"
      "time measure"
      "details details"
      "details details"
      "bank banknumber"
      "iban accountnumber"
      "submit status";
    row-gap: 60px;
    column-gap: 15%;
  
    .applicant { grid-area: applicant; }
    .email { 
      grid-area: email; 
      display: flex;
      justify-content: stretch;
    }
    .tel { grid-area: tel; }
    .time { grid-area: time; }
    .measure { grid-area: measure; }
    .bank { grid-area: bank; }
    .banknumber { grid-area: banknumber; }
    .iban { grid-area: iban; }
    .accountnumber { grid-area: accountnumber; }
    .details { grid-area: details; }
    .submit { grid-area: submit; }
    .form-status { grid-area: status; }

    .submit {
      width: fit-content;
      position: relative;

      border: none;
      background-color: #489297;
      color: white;
      font-weight: 700;
      font-size: 1.3em;
      padding: 10px 25px;
      border-radius: 5px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      top: 0;
    
      transition: top 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    
      &:active {
        top: 2px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      }
    }

  }

}

@media screen and (max-width: 900px) {

  form section {
    display: flex;
    flex-direction: column;
  }

}