main.confirmation-main {
  text-align: center;

  article {

    margin: 50px 0;
    height: auto;

    p {
      text-align: center;
    }

  }

}