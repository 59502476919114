
header {

  display: flex;
  padding: 10px 50px 10px 20px;

  align-items: center;
  justify-content: space-between;

  .heading {
    display: flex;
    align-items: center;

    $line-spacing: 50px;
    .schul-logo {
      width: 100px + $line-spacing;
      height: 100px;
      padding-right: $line-spacing;

      background-image: url("./../../media/images/annette-logo.jpg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    h1 {
      border-left: 1px solid black;
      font-size: 3em;
      height: 70%;
      display: inline-table;
      padding-left: $line-spacing;

      span {
        font-weight: 1000;
        display: table-cell;
        vertical-align: middle;
      }
    }

  }

}

@media screen and (max-width: 850px) {

  header {
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;
    height: auto;

    .heading {
      flex-direction: column;

      .schul-logo {
        width: 100px;
        height: 100px;
      }

      h1 {
        padding: 0;
        border: none;
        text-align: center;
      }

    }

  }

}

@media screen and (max-width: 650px) {

  header {
    padding: 10px;
  }

}

@media screen and (max-width: 360px) {

  header .heading h1 {
    font-size: 2em;
  }

}