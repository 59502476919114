section {
  margin-bottom: 100px;
}

.contact .links {

  padding: 30px 0;
  display: flex;
  gap: 50px;



  li { list-style: none; }

}


@media screen and (max-width: 370px) {
  
  .contact .links {
    flex-direction: column;
  }

}

.flex {
  width: 100%;
  display: flex;
  gap: 10%;
  align-items: flex-start;
  justify-content: flex-start;

  .text-section {
    flex: 3;
  }

  .big-text-section {
    flex: 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    h1 {
      width: min-content;
      background-color: #489297;
      padding: 10px;
      color: white;
      font-size: 5em;

      word-wrap: normal;
      word-break: normal;
      hyphens: auto;
    }
  } 

}

@media screen and (max-width: 1100px) {
  
  .flex {
    flex-direction: column-reverse;

    .big-text-section {
      flex: unset;
      width: 100%;
    }

  }

}
@media screen and (max-width: 650px) {
  
  .flex .big-text-section {

    h1 {
      font-size: 3em;
    }
  }

}
@media screen and (max-width: 360px) {
  
  .flex .big-text-section {

    h1 {
      font-size: 2em;
    }
    
  }

}