section {
  margin-bottom: 100px;
}

.contact .links {
  padding: 30px 0;
  display: flex;
  gap: 50px;
}
.contact .links li {
  list-style: none;
}

@media screen and (max-width: 370px) {
  .contact .links {
    flex-direction: column;
  }
}
.flex {
  width: 100%;
  display: flex;
  gap: 10%;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex .text-section {
  flex: 3;
}
.flex .big-text-section {
  flex: 2;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex .big-text-section h1 {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  background-color: #489297;
  padding: 10px;
  color: white;
  font-size: 5em;
  word-wrap: normal;
  word-break: normal;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

@media screen and (max-width: 1100px) {
  .flex {
    flex-direction: column-reverse;
  }
  .flex .big-text-section {
    flex: unset;
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .flex .big-text-section h1 {
    font-size: 3em;
  }
}
@media screen and (max-width: 360px) {
  .flex .big-text-section h1 {
    font-size: 2em;
  }
}/*# sourceMappingURL=aboutView.css.map */