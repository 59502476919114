.template-button {
  position: relative;

  border: none;
  background-color: #489297;
  color: white;
  font-weight: 700;
  font-size: 1.3em;
  padding: 10px 25px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  top: 0;

  transition: top 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

  &:active {
    top: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }

}