.buttons {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 50px;

  .erneut-senden {
    background-color: white;
    box-shadow: none;
    color: rgba(0, 0, 0, 0.5);
  }

}

@media screen and (max-width: 680px) {
  .buttons {
    flex-direction: column;
    gap: 0;
  }
}