.disclaimer {
  margin-bottom: 100px;
}

form section {
  display: grid;
  grid-template-areas: "applicant ." "email tel" "time measure" "details details" "details details" "bank banknumber" "iban accountnumber" "submit status";
  row-gap: 60px;
  -moz-column-gap: 15%;
       column-gap: 15%;
}
form section .applicant {
  grid-area: applicant;
}
form section .email {
  grid-area: email;
  display: flex;
  justify-content: stretch;
}
form section .tel {
  grid-area: tel;
}
form section .time {
  grid-area: time;
}
form section .measure {
  grid-area: measure;
}
form section .bank {
  grid-area: bank;
}
form section .banknumber {
  grid-area: banknumber;
}
form section .iban {
  grid-area: iban;
}
form section .accountnumber {
  grid-area: accountnumber;
}
form section .details {
  grid-area: details;
}
form section .submit {
  grid-area: submit;
}
form section .form-status {
  grid-area: status;
}
form section .submit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  border: none;
  background-color: #489297;
  color: white;
  font-weight: 700;
  font-size: 1.3em;
  padding: 10px 25px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  top: 0;
  transition: top 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}
form section .submit:active {
  top: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 900px) {
  form section {
    display: flex;
    flex-direction: column;
  }
}/*# sourceMappingURL=applicationView.css.map */