header {
  display: flex;
  padding: 10px 50px 10px 20px;
  align-items: center;
  justify-content: space-between;
}
header .heading {
  display: flex;
  align-items: center;
}
header .heading .schul-logo {
  width: 150px;
  height: 100px;
  padding-right: 50px;
  background-image: url("./../../media/images/annette-logo.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
header .heading h1 {
  border-left: 1px solid black;
  font-size: 3em;
  height: 70%;
  display: inline-table;
  padding-left: 50px;
}
header .heading h1 span {
  font-weight: 1000;
  display: table-cell;
  vertical-align: middle;
}

@media screen and (max-width: 850px) {
  header {
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;
    height: auto;
  }
  header .heading {
    flex-direction: column;
  }
  header .heading .schul-logo {
    width: 100px;
    height: 100px;
  }
  header .heading h1 {
    padding: 0;
    border: none;
    text-align: center;
  }
}
@media screen and (max-width: 650px) {
  header {
    padding: 10px;
  }
}
@media screen and (max-width: 360px) {
  header .heading h1 {
    font-size: 2em;
  }
}/*# sourceMappingURL=templateHeader.css.map */