@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  word-wrap: break-all;
  word-break: break-word;
  hyphens: auto;
}

body, html {
  background-color: whitesmoke;
  padding: 0;
  margin: 0;
}

main {
  padding: 100px;
}
header, main {
  background-color: white;
}

  
input, textarea {
  resize: none;
  border: none;
  border-bottom: 1px solid black;
  margin-top: 10px;

  &::placeholder {
    position: relative;
    bottom: 0;
    opacity: 1;
    transition: opacity 0.2s ease, bottom 0.2s ease;
  }

  &:focus {
    outline: none;

    &::placeholder {
      bottom: 5px;
      opacity: 0;
      transition: opacity 0.2s ease;
    }

  }

}

a {
  color: #489297;
  text-decoration: none;
}


/// some font stuff:

p, li {
  font-size: 17px;
  text-align: justify;
}

h1 {
  font-size: 30px;
}

label {
  font-size: 23px;
}

.field {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 400;
    margin-bottom: 20px;
  }

  textarea {
    height: 150px;
  }


}


@media screen and (max-width: 650px) {

  main {
    padding: 30px;
  }

}

@media screen and (max-width: 500px) {

  h1 {
    font-size: 23px;
  }

  label {
    font-size: 15px;
  }

  p, li {
    font-size: 15px;
  }

}